import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {
    GroupGamesResponse,
    GroupInviteResponse,
    GroupMemberRequest,
    GroupMembersResponse,
    GroupRequest,
    GroupResponse,
    GroupsResponse
} from "../../model/group-dtos";
import {UserGameOwnership} from "../../model/user-game-ownership";
import {
    GameListResponse,
    GameListsResponse,
    GamePlayBasicResponse,
    GamePlayPeriodStatsResponse, MeetupResponse,
    Page,
    PostResponse, UserGamesResponse
} from "../../model/responses";
import {UserStatsResponse} from "../../model/statsResponses";
import {DatedPeriod, periodToRequest} from "../../model/period";
import {Visibility} from "../../model/visibility";

@Injectable()
export class GroupService {

    constructor(
        private http: HttpClient,
    ) {
    }

    searchGroups(pageSize: number, pageIndex: number, fromLatitude?: number, fromLongitude?: number): Observable<GroupsResponse> {
        let params = new HttpParams()
            .set('pagination', pageSize + ',' + pageIndex)

        if (fromLatitude && fromLongitude) {
            params = params.append('fromLatitude', fromLatitude.toString());
            params = params.append('fromLongitude', fromLongitude.toString());
        }
        return this.http.get<GroupsResponse>(`${environment.apiUrl}/groups/search`, {params: params});
    }

    getGroup(id: string): Observable<GroupResponse> {
        return this.http.get<GroupResponse>(`${environment.apiUrl}/groups/${id}`);
    }

    getGroupMembers(id: string, pageSize: number, pageIndex: number): Observable<GroupMembersResponse> {
        return this.http.get<GroupMembersResponse>(`${environment.apiUrl}/groups/${id}/members`, {
            params: new HttpParams()
                .set('pagination', pageSize + ',' + pageIndex)
        });
    }

    getGroupCollection(
        id: string,
        listId?: string,
        nameFilter?: string,
        playerCountFilterType?: string,
        playerCountFilter?: number,
        sortProperty?: string,
        sortDirection?: string,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<UserGamesResponse> {
        let params = new HttpParams();
        if (listId) {
            params = params.append('listId', listId)
        }
        if (nameFilter) {
            params = params.append('nameFilter', nameFilter)
        }
        if (playerCountFilterType && playerCountFilter) {
            params = params.append('playerCountFilterType', playerCountFilterType)
            params = params.append('playerCountFilter', playerCountFilter.toString())
        }
        if (sortProperty && sortDirection) {
            params = params.append('sort', `${sortProperty},${sortDirection}`)
        }
        if (pageSize != undefined && pageIndex != undefined) {
            params = params.append('pagination', `${pageSize},${pageIndex}`)
        }
        return this.http.get<UserGamesResponse>(
            `${environment.apiUrl}/groups/${id}/boardgames`,
            {params: params}
        )
    }

    getGroupCollectiveCollection(
        id: string,
        ownership?: UserGameOwnership,
        listId?: string,
        nameFilter?: string,
        playerCountFilterType?: string,
        playerCountFilter?: number,
        sortProperty?: string,
        sortDirection?: string,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<GroupGamesResponse> {
        let params = new HttpParams();
        if (ownership) {
            params = params.append('ownership', ownership.toString())
        }
        if (listId) {
            params = params.append('listId', listId)
        }
        if (nameFilter) {
            params = params.append('nameFilter', nameFilter)
        }
        if (playerCountFilterType && playerCountFilter) {
            params = params.append('playerCountFilterType', playerCountFilterType)
            params = params.append('playerCountFilter', playerCountFilter.toString())
        }
        if (sortProperty && sortDirection) {
            params = params.append('sort', `${sortProperty},${sortDirection}`)
        }
        if (pageSize != undefined && pageIndex != undefined) {
            params = params.append('pagination', `${pageSize},${pageIndex}`)
        }
        return this.http.get<GroupGamesResponse>(
            `${environment.apiUrl}/groups/${id}/boardgames/collective`,
            {params: params}
        )
    }

    getGroupGameLists(id: string): Observable<GameListsResponse> {
        return this.http.get<GameListsResponse>(`${environment.apiUrl}/groups/${id}/boardgames/lists`);
    }

    updateGameListGames(id: string, listId: string, addedIds: string[], removedIds: string[]) {
        const request = {addedGameIds: addedIds, removedGameIds: removedIds};
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/boardgames/lists/${listId}/games`, request);
    }

    updateGameLists(id: string, gameId: string, listIds: string[]) {
        const request = {listIds: listIds};
        return this.http.put<any>(`${environment.apiUrl}/groups/${id}/boardgames/${gameId}/lists`, request);
    }

    createGameList(id: string, name: string): Observable<GameListResponse> {
        return this.http.post<GameListResponse>(`${environment.apiUrl}/groups/${id}/boardgames/lists`, {name: name});
    }

    updateGameList(id: string, listId: string, name: string): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/groups/${id}/boardgames/lists/${listId}`, {name: name});
    }

    deleteGameList(id: string, listId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/groups/${id}/boardgames/lists/${listId}`);
    }

    getGroupGamePlays(id: string, period: DatedPeriod, pageSize: number, pageIndex: number): Observable<Page<GamePlayBasicResponse>> {
        return this.http.get<Page<GamePlayBasicResponse>>(`${environment.apiUrl}/groups/${id}/gameplays`, {
            params: new HttpParams()
                .set('pagination', pageSize + ',' + pageIndex)
                .set('period', periodToRequest(period))
        });
    }

    getGroupGamePlayPeriodStats(id: string, period: DatedPeriod): Observable<GamePlayPeriodStatsResponse> {
        return this.http.get<GamePlayPeriodStatsResponse>(`${environment.apiUrl}/groups/${id}/gameplays/stats`, {
            params: new HttpParams()
                .set('period', periodToRequest(period))
        });
    }

    getGroupFeed(id: string, pageSize: number = 50, pageIndex: number = 0): Observable<Page<PostResponse>> {
        return this.http.get<Page<PostResponse>>(`${environment.apiUrl}/groups/${id}/feed`, {
            params: new HttpParams().set('pagination', pageSize + ',' + pageIndex)
        });
    }

    getGroupStats(id: string, period: DatedPeriod): Observable<UserStatsResponse> {
        return this.http.get<UserStatsResponse>(`${environment.apiUrl}/groups/${id}/stats`, {
            params: new HttpParams()
                .set('period', periodToRequest(period))
        });
    }

    getGroupMeetups(id: string): Observable<Page<MeetupResponse>> {
        return this.http.get<Page<MeetupResponse>>(`${environment.apiUrl}/groups/${id}/meetups`);
    }

    createGroup(request: GroupRequest, attachment?: Blob): Observable<GroupResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        if (attachment) {
            formData.append(`attachment-0`, attachment);
        }
        return this.http.post<GroupResponse>(`${environment.apiUrl}/groups`, formData);
    }

    updateGroup(id: string, request: GroupRequest, attachment?: Blob): Observable<any> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        if (attachment) {
            formData.append(`attachment-0`, attachment);
        }
        return this.http.put<any>(`${environment.apiUrl}/groups/${id}`, formData);
    }

    deleteGroup(id: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/groups/${id}`);
    }

    joinGroup(id: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/join`, {});
    }

    inviteToGroup(id: string): Observable<GroupInviteResponse> {
        return this.http.post<GroupInviteResponse>(`${environment.apiUrl}/groups/${id}/members/invite`, {});
    }

    acceptGroupInvitation(id: string, code: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/accept`, {code});
    }

    acceptGroupMembershipRequest(id: string, memberId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}/accept`, {});
    }

    rejectGroupMembershipRequest(id: string, memberId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}/reject`, {});
    }

    removeMember(id: string, memberId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}`);
    }

    updateGroupMember(id: string, memberId: string, request: GroupMemberRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}`, request);
    }
}

export function isGroupVisible(group: GroupResponse): boolean {
    if (group.ownRole) {
        return true;
    }
    return group.visibility == Visibility.PUBLIC;
}
