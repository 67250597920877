import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private renderer: Renderer2;
    private currentTheme: string = 'dark'

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    setTheme(theme: 'light' | 'dark'): void {
        const body = document.body;

        this.renderer.removeClass(body, `theme-${this.currentTheme}`);
        this.renderer.removeClass(body, `theme-${this.currentTheme}-root`);

        this.renderer.addClass(body, `theme-${theme}`);
        this.renderer.addClass(body, `theme-${theme}-root`);
        this.currentTheme = theme
        localStorage.setItem('theme', theme)
    }

    getTheme(): string {
        return this.currentTheme;
    }
}
